import { useEffect } from 'react';

const DynamoDresden = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 910);
    localStorage.setItem('club_name', 'Dynamo Dresden');

    window.location.assign('/');
  });

  return null;
};
export default DynamoDresden;
